






























import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    exportLog: Array
  }
})

export default class ExportLog extends Vue {
}
