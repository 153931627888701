




















import Vue from 'vue'
import Component from 'vue-class-component'
import exportLog from '@/components/export/log.vue'
import utils from "@/utils/util.ts"
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global'

@Component({
  mixins: [globalMixins],
  components: {
    exportLog
  }
})
export default class ATSViewsExportLog extends Vue<globalMixins> {
  @Action('getExportLog') getExportLog: Function
  @Getter('exportLog') exportLog: Object[]
  @Getter('logTotal') logTotal: Number

  pagination: any = {
    page: 1,
    size: 10
  }
  loading: boolean = false
  project: any = JSON.parse(localStorage.getItem("projects"))
  created () {
    this.doSearch()
  }
  doSearch () {
    this.loading = true
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = {
      projectId: this.project.id,
      limit: pagination.limit,
      offset: pagination.offset
    }
    this.getExportLog({ params }).then(() => {
      this.loading = false
    })
  }
  onPagerSizeChange (val) {
    this.pagination.size = val
    this.doSearch()
  }
  onPagerCurrentChange (val) {
    this.pagination.page = val
    this.doSearch()
  }
}
